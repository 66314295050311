<template>
  <CCard>
    <CCardHeader><h4 class="subhead-modal">Concept Tank</h4></CCardHeader>
    <CCardBody>
      <CRow class="mb-2">
        <CCol sm="10">
          <CLink
            class="card-header-action btn-minimize"
            @click="formCollapsed = !formCollapsed"
          >
            <CIcon
              size="lg"
              name="cil-chevron-double-down"
              v-if="formCollapsed"
            />
            <CIcon size="lg" name="cil-chevron-double-up" v-else />
          </CLink>
          <label><b>Search box</b></label>
        </CCol>
      </CRow>

      <CCollapse :show="formCollapsed">
        <CRow>
          <CCol md="4">
            <CCard class="card border-secondary">
              <CCardHeader>
                <label>Creator details</label>
              </CCardHeader>
              <CCardBody>
                <CInput label="Creator" v-model="searchObj.Creator" />
                <CSelect
                  label="Business Unit"
                  :options="buList"
                  placeholder=""
                  v-model="searchObj.BU"
                  :value.sync="searchObj.BU"
                />
              </CCardBody>
            </CCard>
          </CCol>
          <CCol md="4">
            <CCard class="card border-secondary">
              <CCardHeader>
                <label>Market / Customer</label>
              </CCardHeader>
              <CCardBody>
                <CSelect
                  label="Industry"
                  :options="IndustryList"
                  placeholder=""
                  v-model="searchObj.Industry"
                  :value.sync="searchObj.Industry"
                />
                <CSelect
                  label="Segment"
                  :options="SegmentList"
                  placeholder=""
                  @change="segmentOnChange($event)"
                  :value.sync="searchObj.Segment"
                  v-model="searchObj.Segment"
                />
                <CSelect
                  label="Sub Segment"
                  :options="SubsegmentFilter"
                  placeholder=""
                  v-model="searchObj.SubSegment"
                  :value.sync="searchObj.SubSegment"
                />
              </CCardBody>
            </CCard>
          </CCol>
          <CCol md="4">
            <CCard class="card border-secondary">
              <CCardHeader><label>Concept detail</label></CCardHeader>
              <CCardBody>
                <CInput label="Concept No." v-model="searchObj.ConceptNo" />
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CCollapse>

      <CRow>
        <CCol></CCol>
        <CCol class="text-center">
          <CInput label="Keyword" v-model="searchObj.ConceptName" horizontal>
            <template #prepend-content
              ><CIcon name="cil-magnifying-glass"
            /></template>
          </CInput>
        </CCol>
        <CCol></CCol>
      </CRow>
      <CRow>
        <CCol class="text-center">
          <CButton color="info-custom" class="mr-1" @click ="OnExport()">Export</CButton>
          <CButton color="dark" class="mr-1 text-white" @click="ClearValue()">Clear</CButton>
          <CButton color="primary" class="text-white" @click="SearchConcept()"
            >Search</CButton
          >
        </CCol>
      </CRow>
      <CRow>
        <CCol md="12">
          <CDataTable
            :items="ConceptTankData"
            :fields="fields"
            items-per-page-select
            :items-per-page="10"
            hover
            sorter
            pagination
            :loading="isLoading"
          >
            <!-- <template #action>
              <td>
                <CButton size="sm" class="mr-1" color="success" variant="ghost"
                  ><CIcon name="cil-thumb-up"></CIcon
                ></CButton>
                <CButton size="sm" color="info" v-on:click="OnclickDetail()">View</CButton>
              </td>
            </template> -->
            <template #ConceptNo="{item}">
              <td>
                <CLink class="text-primary" @click="OnclickDetail(item)">{{
                  item.ConceptNo
                }}</CLink>
              </td>
            </template>
          </CDataTable>
        </CCol>
      </CRow>
    </CCardBody>

    <ConceptModal
      :showModal.sync="ConceptModal"
      @ConceptModal-updated="ConceptModal = $event"
      :item.sync="conceptObj"
      :viewmode="viewmode"
    />

  </CCard>
</template>

<script>
import ConceptModal from "../concept/ConceptModal";
import axios from "axios";
import store from "../../store";


const fields = [
  { key: "ConceptNo", label: "Concept No." },
  { key: "IndustryDesc", label: "Industry" },
  { key: "SegmentDesc", label: "Segment" },
  { key: "ConceptName", label: "Concept Name" },
  { key: "ConceptCreator", label: "Creator" },
  { key: "ShowApprovedDate", label: "Approved Date" },
  //{ key: "status", label: "Status" },
  // {
  //   key: "action",
  //   label: "Action",
  //   sorter: false,
  //   filter: false
  // }
];

export default {
  name: "ConceptTank",
  components: {
    ConceptModal
  },
  data() {
    return {
      viewmode: "view",
      SelectOption: [
        { value: "", label: "" },
        { value: "1", label: "option 1" },
        { value: "2", label: "option 2" }
      ],
      fields,
      formCollapsed: false,
      ConceptModal: false,
      conceptObj: null,
      IndustryList: [{ value: null, label: "--Select--" }],
      SegmentList: [{ value: null, label: "--Select--" }],
      SubSegmentList: [{ value: null, label: "--Select--" }],
      buList: [{ value: null, label: "--Select--" }],
      searchObj: {
        Creator: "",
        BU: "",
        Industry: "",
        Segment: "",
        SubSegment: "",
        ConceptNo: "",
        ConceptName:""
      },
      SubsegmentFilter: [{ value: null, label: "--Select--" }],
      ConceptTankData: [],
      isLoading: true,
      // userprofile: JSON.parse(localStorage.getItem("userprofile"))
      userprofile: JSON.parse(localStorage.getItem("userprofile"))
    };
  },
  methods: {
    GetBu(){
      axios.get(store.getters.URL +'/api/MasterBu/getall').then((res) => {
        let options = [{ value: "", label: "-- Select --" }];
        //console.log("res", res);
        if (res !== null) {
          res.data.forEach(function (data) {
            options.push({
              value: data.BUID,
              label: data.BUDesc,
            });
          });
        }
        this.buList = options;
      });
    },
    OnExport()
    {
      //console.log(this.ConceptTankData)
      let dataForExport = []
      Array.from(this.ConceptTankData).forEach((item) => {
          let format = {
            ConceptNo: item.ConceptNo,
            Industry: item.IndustryDesc,
            Segment: item.SegmentDesc,
            ConceptName: item.ConceptName,
            Creator: item.ConceptCreator,
            ApprovedDate: item.ShowApprovedDate        
          };
          dataForExport.push(format)
        });
      this.OnExportToExcel("ConceptTank", dataForExport)
    },
    OnclickDetail(item) {
      //console.log("item",item);
      this.conceptObj = { ConceptNo : item.ConceptNo, ConceptStatus : item.Status};
      this.ConceptModal = !this.ConceptModal;
    },
    GetIndustry() {
      axios.get(store.getters.URL + "/api/MasterIndustry/getall").then(res => {
        let Options = [{ value: null, label: "-- Select --" }];
        res.data.forEach(function(data) {
          Options.push({
            value: data.IndustryID,
            label: data.IndustryDesc
          });
        });
        this.IndustryList = Options;
      });
    },
    GetSegment() {
      axios.get(store.getters.URL + "/api/MasterSegment/getall").then(res => {
        let Options = [{ value: null, label: "-- Select --" }];
        res.data.forEach(function(data) {
          Options.push({
            value: data.SegmentID,
            label: data.SegmentDesc
          });
        });
        this.SegmentList = Options;
      });
    },
    GetSubSegment() {
      axios
        .get(store.getters.URL + "/api/MasterSubSegment/getall")
        .then(res => {
          this.SubSegmentList = res.data;
        });
    },
    segmentOnChange(event) {
      var MySubSegmentList = this.SubSegmentList.filter(function(obj) {
        return obj.SegmentID == event.target.value;
      }); //.filter(x => x.SegmentID = event.target.value)

      let Options = [{ value: null, label: "--Select--" }];
      MySubSegmentList.forEach(function(data) {
        Options.push({
          value: data.SubSegmentID,
          label: data.SubSegmentDesc
        });
      });

      this.SubsegmentFilter = Options;

      this.searchObj.SubSegment = null;
    },
    GetConceptTankData() {
      axios
        .get(store.getters.URL + "/api/IdeationConcept/getconcepttankdata", {
          params: {
            username: this.userprofile.Username
          }
        })
        .then(res => {
          //console.log(res);
          let Obj = JSON.parse(res.data.Value);
          this.ConceptTankData = Obj;
          this.isLoading = false;
          //console.log("getResult",this.ConceptTankData)
        })
        .catch(err => {
          console.error(err);
          this.isLoading = false;
        });
    },
    SearchConcept() {
      this.isLoading = true;
      let item = {
        Creator: this.searchObj.Creator,
        BU: this.searchObj.BU,
        Industry: this.searchObj.Industry,
        Segment: this.searchObj.Segment,
        SubSegment: this.searchObj.SubSegment,
        ConceptNo: this.searchObj.ConceptNo,
        ConceptName:this.searchObj.ConceptName,
        Username : this.userprofile.Username
      };
      axios
        .get(store.getters.URL + "/api/IdeationConcept/searchconcepttankdata", {
          params: item
        })
        .then(res => {
          //console.log("searchResult",res.data);
          this.ConceptTankData = res.data;
          this.isLoading = false;
        })
        .catch(err => {
          console.error(err.error);
          this.isLoading = false;
        });
    },
    ClearValue(){
      this.searchObj.Creator =""
      this.searchObj.BU =""
      this.searchObj.Industry = ""
      this.searchObj.Segment = ""
      this.searchObj.SubSegment = ""
      this.searchObj.ConceptNo = ""
      this.searchObj.ConceptName = ""
      
    }
  },
  mounted() {
    // console.log("params = ",this.$route.params.DocNo);
    // let DocNo = this.$route.params.DocNo || "";
    // this.viewmode = this.$route.params.Mode || "view";
    
    // console.log("DocNo = ",DocNo);
    // if(DocNo !== ""){
    //     let item = { ConceptNo : DocNo}
    //     this.OnclickDetail(item)
    //   //OnclickDetail(item) {
    //   //this.conceptObj = { ConceptNo : item.ConceptNo};

    // }

    this.GetIndustry();
    this.GetBu();
    this.GetSegment();
    this.GetSubSegment();
    this.GetConceptTankData();
  }
};
</script>
